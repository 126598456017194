import 'bootstrap'

import '../main.scss'
import { backendDomain, frontendDomain, protocol } from '../constants.js'
import { redirectRegisteredUser, setCookie, validateEmail, setCommonHtml } from '../common_js.js'

const loginButton = document.getElementById('login')
const email = document.getElementById('email')
const password = document.getElementById('password')
const errorsDiv = document.getElementById('errors')

function checkPassword (str) {
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
  return re.test(str)
}

function oneLineTag (tag, options) {
  return Object.assign(document.createElement(tag), options)
}

const showSpinner = () => {
  document.getElementById('spinner-signup').style.display = 'inline-block'
}

const hideSpinner = () => {
  document.getElementById('spinner-signup').style.display = 'none'
}

const showLoginButton = () => {
  document.getElementById('login').style.display = 'inline-block'
}

const hideLoginButton = () => {
  document.getElementById('login').style.display = 'none'
}

function getFieldsErrors () {
  const errorsList = document.createDocumentFragment()
  if (!validateEmail(email.value)) {
    const quickAnchor = {
      innerText: '- Invalid email'
    }
    const error = oneLineTag('p', quickAnchor)
    errorsList.appendChild(error)
  }
  if (!checkPassword(password.value)) {
    const quickAnchor = {
      innerText: '- Password must contain 8 characters, a symbol, upper and lower case letters and number'
    }
    const error = oneLineTag('p', quickAnchor)
    errorsList.appendChild(error)
  }

  return errorsList
}

async function login () {
  const options = {
    method: 'POST',
    headers: new Headers({
      Origin: `${protocol}://${backendDomain}`,
      'Accept-Encoding': 'gzip, deflate',
      'Accept-Language': 'en-US,en;q=0.8',
      'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      Referer: `${protocol}://${frontendDomain}`,
      'X-Requested-With': 'XMLHttpRequest',
      Connection: 'keep-alive'
    }),
    mode: 'cors'
  }
  options.body = JSON.stringify({
    email: email.value,
    password: password.value
  })
  return await fetch(`${protocol}://${backendDomain}/login`, options)
}

function displayRunRequest () {
  hideLoginButton()
  email.setAttribute('disabled', true)
  password.setAttribute('disabled', true)
  showSpinner()
}

function displayReceivedRequest () {
  showLoginButton()
  email.removeAttribute('disabled')
  password.removeAttribute('disabled')
  hideSpinner()
}

async function handleLoginResponses (loginResponse, errorsList) {
  if (loginResponse.status === 200) {
    const jsonResponse = await loginResponse.json()
    setCookie('token', jsonResponse.token)
    window.location.href = '/'
  } else if (loginResponse.status === 403) {
    displayReceivedRequest()
    const quickAnchor = {
      innerText: '- Wrong email or password'
    }
    const error = oneLineTag('p', quickAnchor)
    errorsDiv.replaceChildren()
    errorsDiv.appendChild(error)
  } else if (loginResponse.status === 409) {
    displayReceivedRequest()
    const quickAnchor = {
      innerText: '- You need to verify email before login'
    }
    const error = oneLineTag('p', quickAnchor)
    errorsDiv.replaceChildren()
    errorsDiv.appendChild(error)
  } else {
    displayReceivedRequest()
    const quickAnchor = {
      innerText: '- Unexpected error during login'
    }
    const error = oneLineTag('p', quickAnchor)
    errorsDiv.replaceChildren()
    errorsDiv.appendChild(error)
  }
}

async function loginFlow (e) {
  e.preventDefault() // Don't scroll to the top of the page
  const errorsList = getFieldsErrors()
  if (errorsList.children.length > 0) {
    errorsDiv.replaceChildren()
    errorsDiv.appendChild(errorsList)
  } else {
    displayRunRequest()
    try {
      const loginResponse = await login()
      await handleLoginResponses(loginResponse, errorsList)
    } catch {
      displayReceivedRequest()
      const quickAnchor = {
        innerText: '- Service not available'
      }
      const error = oneLineTag('p', quickAnchor)
      errorsDiv.replaceChildren()
      errorsDiv.appendChild(error)
    }
  }
}

function showEmailAlert () {
  const url = window.location.href
  if (url.includes('email-alert')) {
    const emailAlert = document.getElementById('email-alert')
    emailAlert.style.display = 'block'
  }
  if (url.includes('change-password')) {
    const emailAlert = document.getElementById('success-change-password-alert')
    emailAlert.style.display = 'block'
  }
}

showEmailAlert()
const enterLogin = function (event) {
  if (event.key === 'Enter') {
    event.preventDefault()
    loginButton.click()
  }
}

setCommonHtml()
redirectRegisteredUser()

email.addEventListener('keyup', enterLogin)
password.addEventListener('keyup', enterLogin)
loginButton.addEventListener('click', (e) => loginFlow(e), false)
